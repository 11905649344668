(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

    var lazyLoadSlideshow = function () {
        // Lazy load all slideshow images after the initial
        [].forEach.call(document.querySelectorAll('.cycle-slideshow img[data-src]'), function (img) {
            img.setAttribute('src', img.getAttribute('data-src'));
            img.setAttribute('srcset', img.getAttribute('data-srcset'));
            img.setAttribute('sizes', img.getAttribute('data-sizes'));
            img.onload = function () {
                img.removeAttribute('data-src');
                img.removeAttribute('data-sizes');
            };
        });
    };

    fp.home = (function () {
        return {
            init: function () {
                $('.homepage-link').addClass('selected');

                // =========================================
                // Carousel controls
                // =========================================

                var carouselWrapper = $('.slideshow');
                var carouselTarget = $('.cycle-slideshow');
                var ariaLiveRegion = $('#control-announcements');
                $(carouselTarget).cycle();
                lazyLoadSlideshow();

                //$(carouselWrapper).on("mouseenter focusin", function () {
                    //if (!$(carouselTarget).hasClass('hard-pause')) {
                        //$(carouselTarget).cycle('pause');
                    //};
                //});

                //$(carouselWrapper).on("mouseleave focusout", function () {
                    //if (!$(carouselTarget).hasClass('hard-pause')) {
                        //$(carouselTarget).cycle('resume');
                    //};
                //});

                $('#cycle-pause-play').click(function () {
                    if ($(carouselTarget).hasClass('hard-pause')) {
                        $(carouselTarget).removeClass('hard-pause').cycle('resume');
                        $(this).text('Pause').attr('aria-label', 'Pause Carousel');
                        $(ariaLiveRegion).text('Carousel is playing.');
                    } else {
                        $(carouselTarget).addClass('hard-pause').cycle('pause');
                        $(this).text('Play').attr('aria-label', 'Play Carousel');
                        $(ariaLiveRegion).text('Carousel is paused.');
                    }
                });

                $('#cycle-back').click(function () {
                    $(ariaLiveRegion).text('Reading previous slide.');
                });

                $('#cycle-next').click(function () {
                    $(ariaLiveRegion).text('Reading next slide.');
                });

                // =========================================
                // Detect sizes
                // =========================================

                function sizeDetector() {
                    // Define header and mobile utility height and set css variable

                    const root = document.documentElement;

                    var headerHeight = $('header').outerHeight();
                    if (headerHeight === undefined || headerHeight === null) {
                        headerHeight = 0;
                    }
                    root.style.setProperty("--header-height", headerHeight + "px");

                    var logoMobileHeight = $('#logo-mobile').outerHeight();
                    if (logoMobileHeight === undefined || logoMobileHeight === null) {
                        logoMobileHeight = 0;
                    }
                    root.style.setProperty("--logo-mobile-height", logoMobileHeight + "px");

                    var utilityHeight = $('#utility').outerHeight();
                    if (utilityHeight === undefined || utilityHeight === null) {
                        utilityHeight = 0;
                    }
                    root.style.setProperty("--utility-height", utilityHeight + "px");

                    var specialMessageHeight = $('#special-message.message-banner').outerHeight();
                    if (specialMessageHeight === undefined || specialMessageHeight === null || $('#special-message.message-banner').css('display') === 'none') {
                        specialMessageHeight = 0;
                    }
                    root.style.setProperty("--special-message-height", specialMessageHeight + "px");

                    var stickyFooterHeight = $('#ao-sticky').outerHeight();
                    if (stickyFooterHeight === undefined || stickyFooterHeight === null || $('#ao-sticky').css('display') === 'none') {
                        stickyFooterHeight = 0;
                    }
                    root.style.setProperty("--sticky-footer-height", stickyFooterHeight + "px");
                }

                // =========================================
                // Window Resize Handler
                // =========================================

                // Function to be called after window resize
                function handleResize() {
                    sizeDetector();
                    //console.log("Window has finished resizing!");
                }

                // Debounce function
                function debounce(func, delay) {
                    let timeoutId;
                    return function () {
                        const context = this;
                        const args = arguments;
                        clearTimeout(timeoutId);
                        timeoutId = setTimeout(() => {
                            func.apply(context, args);
                        }, delay);
                    };
                }

                // Debounced version of handleResize
                const debouncedResizeHandler = debounce(handleResize, 250); // Adjust delay as needed

                // Event listener for window resize
                window.addEventListener('resize', debouncedResizeHandler);

                // Triggered on initial load
                window.addEventListener('load', function () {
                    setTimeout(handleResize, 250); // Same delay as for window resizing
                });
            }
        };
    })();
})(window, $);
